<template>
  <div class="main-styles">
    <p>
      桌球<br />
      一般规则<br />
      1、所有注单都以全场赛事结束视为有效，除非另有特别说明。<br />
      2、如果一个球员无法完成比赛，所有投注此球员的注单被视为无效。<br />
      3、如果一个球员在比赛结束前弃权或被取消参赛资格，所有此赛事的投注将被视为无效，除非个别投注类型规则另有特别说明。<br />
      4、如果赛事延期或中断，只要比赛最后有完成，所有投注将保持有效。<br />
      5、如果比赛提前开赛，仅有在开赛之前投注的注单将为视为有效投注。开赛后投注的注单将被视为无效，&nbsp;滚球玩法除外。<br />
      <br />
      投注类型<br />
      独赢盘<br />
      &nbsp;&nbsp;&nbsp;1、预测哪个球员将赢取比赛胜利。这个比赛包含双方球员。<br />
      <br />
      让分盘<br />
      &nbsp;&nbsp;&nbsp;1、预测哪个球员将在指定的让局比赛获得胜利。<br />
      <br />
      大/小<br />
      &nbsp;&nbsp;&nbsp;1、预测一场比赛的总比分是大于还是小于指定盘口。<br />
      &nbsp;&nbsp;&nbsp;2、如果一场赛事中断，大/小投注仅会结算任何将来的潜在得分不会影响到赛果的投注。任何其他的情况，投注将被视为无效。<br />
      <br />
      单/双<br />
      &nbsp;&nbsp;&nbsp;1、预测一场赛事的总比分是单数还是双数。<br />
      <br />
      局数投注<br />
      &nbsp;&nbsp;&nbsp;1、预测哪个球员将会赢得一场指定赛事特定局数的胜利。<br />
      &nbsp;&nbsp;&nbsp;2、投注的结算将依据WPBSA最终公布的局数获胜者为准。
    </p>
  </div>
</template>
